import { useState, useEffect } from 'react';

import VehicleDetails from './components/VehicleDetails';
import AutocompleteDropdown from './components/AutocompleteDropdown';

import logo from './favicon.png';

const App = () => {
  const [allData, setAllData] = useState({});
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYearId, setSelectedYearId] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countrySupportYear, setCountrySupportYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const [resetKey, setResetKey] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch('/makeModel.json')
      .then((response) => response.json())
      .then((data) => {
        setAllData(data);
        const makesOptions = Object.keys(data).map((make) => ({ value: make, label: make }));
        setMakes(makesOptions);
      });

    fetch('/countryYear.json')
      .then((response) => response.json())
      .then((data) => {
        const countryOptions = Object.entries(data).map(([country, year]) => ({
          value: country,
          label: country,
          supportYear: year,
        }));
        setCountries(countryOptions);
      });
  }, []);

  useEffect(() => {
    if (countrySupportYear && years.length > 0) {
      const filteredAndMaskedYearOptions = years.reduce((acc, option) => {
        const yearRange = option.label.split('-').map(Number); // Convert to numbers
        const startYear = yearRange[0];
        const endYear = yearRange[1] || startYear; // Use startYear if endYear is undefined

        // If the country's supported year is within the year range of the option, include it
        if (countrySupportYear <= endYear) {
          const maskedStartYear = Math.max(startYear, countrySupportYear);
          const maskedLabel = `${maskedStartYear}${
            endYear === maskedStartYear ? '' : '-' + endYear
          }`.trim();
          acc.push({ ...option, label: maskedLabel });
        }
        return acc;
      }, []);

      setYears(filteredAndMaskedYearOptions);
    }
  }, [countrySupportYear, years]);

  const handleMakeChange = (selectedMake) => {
    setSelectedMake(selectedMake);

    if (selectedMake) {
      const modelsOptions = allData[selectedMake.value]
        .filter((model) => !model.includes('Unknown'))
        .map((model) => ({
          value: model,
          label: model,
        }));
      setModels(modelsOptions);
    } else {
      setModels([]);
    }
    setYears([]);
  };

  const handleModelChange = (selectedModel) => {
    setSelectedModel(selectedModel);
    if (selectedModel) {
      setIsLoading(true); // Start loading
      const searchQuery = `${selectedModel?.label.trim()}`;
      fetch(
        `${process.env.REACT_APP_BACKEND_API}/vehicle/search/${encodeURIComponent(searchQuery)}`
      )
        .then((response) => response.json())
        .then((data) => {
          setSearchResults(data);
          setIsLoading(false); // Stop loading
        })
        .catch((error) => {
          console.error('Error fetching vehicles:', error);
          setIsLoading(false); // Stop loading
        });
    } else {
      setSearchResults(null);
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setCountrySupportYear(selectedOption ? selectedOption.supportYear : null);
  };

  const handleYearChange = (selectedYear) => {
    setSelectedYear(selectedYear);

    setSelectedYearId(selectedYear ? selectedYear.value : null);
  };

  const handleSearch = () => {
    if (selectedYearId) {
      fetch(`${process.env.REACT_APP_BACKEND_API}/vehicle/ids`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: [selectedYearId] }),
      })
        .then((response) => response.json())
        .then((data) => {
          setSearchResults(data);
        })
        .catch((error) => {
          console.error('Error fetching vehicle data:', error);
        });
    } else {
      console.log('No year selected');
      alert('Select the year range first');
    }
  };

  const resetForm = () => {
    setResetKey((prevKey) => prevKey + 1);

    // Reset all dropdown selections to their initial state
    setSelectedMake(null);
    setSelectedModel(null);
    setSelectedYear(null);
    setSelectedCountry(null);

    // Reset other states
    setModels([]);
    setYears([]);
    setSelectedYearId(null);
    setSearchResults(null);
    setCountrySupportYear(null);
  };

  return (
    <>
      <header className='header'>
        <div class='container'>
          <h1 className='site-title'>
            <img className='logo' src={logo} />
            Soarchain Cars
          </h1>
          <p className='instructions'>
            To check the compatibility of your vehicle, select the Make / Model / Country Year of
            your vehicle from the list below.
          </p>

          <p class='text-white'>
            If you don't see your vehicle in the list below please reach us from{' '}
            <a
              class='text-dark'
              style={{ color: 'var(--color-accent)' }}
              href='https://discord.gg/r43PyGyCst'
              target='_blank'
            >
              https://discord.gg/r43PyGyCst
            </a>{' '}
            #-vehicle-discussion channel             
           {' '}
            and state your Country/Make/Model/Year for compatibility check.
          </p>
          <p class='text-white'>
            Kindly be aware that the majority of the vehicles mentioned in the list are
            theoretically compatible by worldwide regulations, but have not undergone testing by the
            Soarchain team. Before buying please make sure that your vehicle is supporting ISO 15765
            (CAN) OBD-II protocol. If your vehicle is marked as compatible on the list but turns out
            to be not compatible with the Motus mini device, you have the option to return the
            device for a full refund.
          </p>
          <p class='text-white' style={{ marginBottom: '3rem' }}>
            Supported cars as per the international OBD-II standards
          </p>

          <div className='inputs-container'>
            <div className='inputs' key={resetKey}>
              <AutocompleteDropdown
                options={makes}
                value={
                  selectedMake ? { value: selectedMake.value, label: selectedMake.label } : null
                }
                onChange={handleMakeChange}
                placeholder='Select Make'
              />
              <AutocompleteDropdown
                options={models}
                value={
                  selectedModel ? { value: selectedModel.value, label: selectedModel.label } : null
                }
                onChange={handleModelChange}
                placeholder='Select Model'
              />
              {/* <AutocompleteDropdown
                options={countries}
                value={
                  selectedCountry
                    ? { value: selectedCountry.value, label: selectedCountry.label }
                    : null
                }
                onChange={handleCountryChange}
                placeholder='Select Country'
              />
              <AutocompleteDropdown
                options={years}
                value={
                  selectedYear ? { value: selectedYear.value, label: selectedYear.label } : null
                }
                onChange={handleYearChange}
                placeholder='Select Year'
              /> */}
            </div>
            <div className='buttons-container'>
              <button onClick={resetForm}>Reset</button>
              {/* <button onClick={handleSearch}>Search</button> */}
            </div>
            {isLoading && (
              <div className='overlay'>
                <div className='spinner'></div>
              </div>
            )}{' '}
            {/* Spinner */}
          </div>
        </div>
      </header>
      <div className='container'>
        {searchResults?.length &&
          searchResults?.map((vehicle) => (
            <VehicleDetails
              key={vehicle._id}
              vehicle={vehicle}
              // country={{ selectedCountry: selectedCountry.label, countrySupportYear }}
            />
          ))}
      </div>
    </>
  );
};

export default App;
