import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'var(--color-bg-secondary)',
    color: 'var(--color-text-light)',
    borderColor: 'var(--color-border)',
    minHeight: '38px',
    minWidth: '300px', // Setting a minimum width
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'var(--color-accent)',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'var(--color-bg-secondary)',
    color: 'var(--color-text-light)',
    boxShadow: '0 4px 11px hsla(0, 0%, 0%, 0.1)',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? 'var(--color-accent)'
      : state.isFocused
      ? 'var(--color-bg-primary)'
      : undefined,
    color: 'var(--color-text-light)',
    '&:active': {
      backgroundColor: 'var(--color-accent)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--color-text-light)',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--color-text-light)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--color-text-light)',
  }),
};

const AutocompleteDropdown = ({ options, onChange, placeholder }) => {
  return (
    <Select styles={customStyles} options={options} onChange={onChange} placeholder={placeholder} />
  );
};

export default AutocompleteDropdown;
